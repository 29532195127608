import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import PageLayout from "../components/page-layout"
import SEO from "../components/seo"

const ArticleHeader = styled.h1`
  display: inline-block;
  border-bottom: 1px solid;
  margin-bottom: 0.75rem;
`

const ArticleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const ArticleDate = styled.p`
  color: #555;
  margin-top: 1rem;
  margin-bottom: 0;
`

const ItemDescription = styled.p`
  padding-bottom: 1rem;
  margin-top: 1rem;
`

const ItemTitle = styled.h3`
  margin-bottom: 0;
`

const ToolItem = styled.div``

const ToolLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const ToolHeader = styled(ArticleHeader)`
  padding-top: 0.25rem;
`

export default function Home({ data }) {
  return (
    <PageLayout>
      <SEO title="Daily Defi"/>
      <div>
        <ArticleHeader>
          Articles
        </ArticleHeader>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <ArticleLink to={node.fields.slug}>
              <ItemTitle>
                {node.frontmatter.title}{" "}
              </ItemTitle>
              <ArticleDate>
                Published: {node.frontmatter.date}
              </ArticleDate>
              <ItemDescription>{node.excerpt}</ItemDescription>
            </ArticleLink>
          </div>
        ))}

        <ToolHeader>
          Tools
        </ToolHeader>
        <ToolLink to="/tools/bao-finance-xdai-farms-tvl">
          <ToolItem>
            <ItemTitle>
              Bao.finance xDai Farms TVL
            </ItemTitle>
            <ItemDescription>
              Calculates the TVL of farms on bao.finance (xdai sidechain).
              Currently only supports baoswap farms.
            </ItemDescription>
          </ToolItem>
        </ToolLink>
        <ToolLink to="/tools/impermanent-loss-calculator">
          <ToolItem>
            <ItemTitle>
              Impermanent Loss Calculator
            </ItemTitle>
            <ItemDescription>
              This tool allows liquidity providers to calculate their potential
              impermanent loss and future theoretical value.
            </ItemDescription>
          </ToolItem>
        </ToolLink>
        <ToolLink to="/tools/calculate-goose-lp-token-value">
          <ToolItem>
            <ItemTitle>
              Calculate Goose Finance LP Token Values
            </ItemTitle>
            <ItemDescription>
              This tool allows users to calculate the worth of their 
              LP Tokens used for staking on Goose Finance.
            </ItemDescription>
          </ToolItem>
        </ToolLink>
        <ToolLink to="/tools/calculate-viking-lp-token-value">
          <ToolItem>
            <ItemTitle>
              Calculate Viking Swap LP Token Values
            </ItemTitle>
            <ItemDescription>
              This tool allows users to calculate the worth of their 
              LP Tokens used for staking on Viking Swap.
            </ItemDescription>
          </ToolItem>
        </ToolLink>
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
